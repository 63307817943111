/* index.scss */

@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700;900&family=Noto+Sans+JP:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

/* 1. import only the necessary Bootstrap files */
@import "bootstrap/scss/functions"; 
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

/* 2. begin customization ---- */

$theme-colors: (
  dark: black,
);

$font-family-sans-serif: "Noto Sans JP", "Inter", sans-serif;
$font-family-serif: "Merriweather", "Roboto Slab", serif;

/* --- end customization ------ */

/* 3. import Bootstrap to set the changes! */
@import "bootstrap";

/* 4. add @mixin or @extend customizations here */

/* create new custom classes from existing classes */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "franklin-gothic-urw",
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.video-container { position: relative; }
.video-container video {
    position: relative;
    z-index: 0;
}
.video-container .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}


// .navbar {
//   min-height: 90px;
//   transition: all .4s ease-in-out;
// }
// .navbar.fixed-top {
//   min-height: 60px;
//   opacity: 0.9;
//   transition: all .4s ease-in-out;
// }

